<template>
  <div>
    <search-form>
      <div class="d-inline-flex flex-wrap mb-3">
        <c-input v-model="search.id" label="ID" search-form />
        <c-input v-model="search.subject" :label="$t('tickets.subject')" search-form />
        <date-picker v-model="search.created.min" search-form :label="$t('shared.createdMin')" />
        <date-picker v-model="search.created.max" search-form :label="$t('shared.createdMax')" />

        <auto-complete
          v-model="search.senderUserId"
          item-text="fullName"
          item-value="id"
          :search="userSearch"
          :label="$t('tickets.senderUser')"
          search-form
        />

        <auto-complete
          v-model="search.receiverUserId"
          item-text="fullName"
          item-value="id"
          :search="userSearch"
          :label="$t('tickets.receiverUser')"
          search-form
        />
      </div>

      <div class="ma-1">
        <v-btn color="success" dark @click="filtering" small :loading="progressing">
          <v-icon small class="me-1"> mdi-filter </v-icon>
          {{ $t('shared.applyFilter') }}
        </v-btn>
      </div>
    </search-form>

    <div class="d-flex mb-4">
      <v-spacer />
      <TicketCreator @refresh="filtering">
        <template #activator="{attrs, on}">
          <v-btn color="success" dark small v-bind="attrs" v-on="on">
            <v-icon small> mdi-plus </v-icon>
            {{ $t('shared.add') }}
          </v-btn>
        </template>
      </TicketCreator>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="context"
      @update:options="filtering"
      :server-items-length="count"
      :loading="progressing"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
      class="elevation-2"
    >
      <template #[`item.actions`]="{item}">
        <div class="d-flex justify-end">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="info" :to="`view/${item.id}`" v-on="on">
                <v-icon> mdi-eye </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.view') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.created`]="{item}">
        <date-display :value="item.created" />
      </template>

      <template #[`item.sender`]="{item}">
        <span v-if="item.sender">{{ item.sender.fullName }}</span>
      </template>

      <template #[`item.receiver`]="{item}">
        <span v-if="item.receiver">{{ item.receiver.fullName }}</span>
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TicketCreator from './components/TicketCreator'
export default {
  name: 'Index',
  components: {
    TicketCreator
  },
  data() {
    return {
      progressing: false,
      count: 1,
      context: {},
      items: [],
      headers: [
        {text: 'ID', value: 'id'},
        {text: this.$t('tickets.subject'), value: 'subject'},
        {text: this.$t('tickets.senderUser'), value: 'sender'},
        {text: this.$t('tickets.receiverUser'), value: 'receiver'},
        {text: this.$t('shared.created'), value: 'created'},
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      search: {
        id: null,
        subject: null,
        senderUserId: null,
        receiverUserId: null,
        created: {min: null, max: null}
      }
    }
  },
  methods: {
    userSearch(filter) {
      return this.$api.org.users.getAllInfo(filter)
    },
    filter(isCount) {
      const filter = this.$filter()

      if (!isCount) {
        filter['sort[0].column'] = this.context.sortBy[0]
        filter['sort[0].type'] = this.context.sortDesc[0] ? 'ASCENDING' : 'DESCENDING'
        filter.size = this.context.itemsPerPage
        filter.page = this.context.page
      }

      return filter
    },
    getItems(filter) {
      return this.$api.ticketsIdn.getAll(filter, this.type).then((res) => {
        this.items = res
      })
    },
    getCount(filter) {
      return this.$api.ticketsIdn.count(filter, this.type).then((res) => {
        this.count = res
      })
    },
    filtering() {
      this.progressing = true
      Promise.all([this.getItems(this.filter()), this.getCount(this.filter(true))])
        .then((res) => {
          this.progressing = false
        })
        .catch((err) => {
          this.$showError(err)
        })
    },
    async removeItem(item) {
      try {
        if (!(await this.$confirm())) return

        await this.$api.ticketsIdn.delete(item.id)
        this.filtering()
        this.$showSuccess(this.$t('tickets.ticketRemoved'))
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
