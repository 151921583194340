<template>
  <v-dialog v-model="open" transition="dialog-bottom-transition" max-width="600" persistent>
    <template v-slot:activator="props">
      <slot name="activator" v-bind="props">
        <v-btn small color="success" v-bind="props.attrs" v-on="props.on">
          <v-icon> mdi-account </v-icon>
        </v-btn>
      </slot>
    </template>

    <ValidationObserver v-slot="{handleSubmit}">
      <v-card>
        <v-toolbar color="primary" dark>{{ $t('tickets.createTicket') }} </v-toolbar>
        <v-card-text class="pt-4">
          <template v-if="!userId">
            <auto-complete
              v-model="model.receiverUserId"
              item-text="fullName"
              item-value="id"
              :search="userSearch"
              :label="$t('tickets.messageTo')"
              rules="required"
            />

            <v-divider class="mb-4" />
          </template>

          <c-input v-model="model.subject" rules="required" :label="$t('tickets.subject')" />
          <v-divider class="mb-4" />

          <c-input v-model="model.message.body" rules="required|max:1000" textarea :label="$t('tickets.text')" />

          <uploader v-model="model.message.attachments" multiple />
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-btn color="success" @click="handleSubmit(submit)"> {{ $t('tickets.send') }}</v-btn>
          <v-btn @click="close" color="error"> {{ $t('shared.close') }} </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
export default {
  name: 'TicketCreator',
  props: {
    userId: {type: [Number, String], default: null}
  },
  data() {
    return {
      open: false,
      model: {
        message: {
          attachments: null,
          body: null
        },
        receiverUserId: this.userId || null,
        subject: null
      }
    }
  },
  methods: {
    close() {
      this.open = false
    },
    userSearch(filter) {
      const userId = this.$store.getters.userId
      return this.$api.org.users.getAllInfo(filter).then((users) => {
        return users.filter((user) => user.id !== userId)
      })
    },
    async submit() {
      try {
        await this.$api.org.tickets.create(this.model)
        this.$showSuccess(this.$t('tickets.ticketsAdded'))
        this.close()
        this.$emit('refresh')
      } catch (err) {
        this.$showError(err)
      }
    }
  }
}
</script>
